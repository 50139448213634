import {MenuItem} from 'components/mui';
import StyledMenuList from 'components/ui/pages/aicp/lookalike-companies/StyledMenuList';
import {RevScoreRange} from 'components/ui/pages/aicp/lookalike-companies/filters/RevScoreFilter';
import StateOption from 'components/ui/filters/StateOption';
import {HeadcountRange} from 'components/ui/filters/HeadcountFilter';
import {RevenueRange} from 'components/ui/filters/RevenueFilter';
import IndustryOption from 'components/ui/filters/IndustryOption';
import {CountryOption} from 'components/ui/filters/CountryFilter';
import AccountFilters from 'core/Aicp/AccountFilters';
import {FilterName, IntRange} from 'core/Filters/index';
import CustomSignalOption from 'components/ui/filters/CustomSignalOption';

const SelectOption = ({
    filterName,
    text,
    isSelected,
    isDisabled,
}: SelectOptionProps) => {
    const optionComponents = {
        [FilterName.REV_SCORE_BINS]: RevScoreRange,
        [FilterName.TUNED_SCORE_BINS]: RevScoreRange,
        [FilterName.HEADCOUNT_BINS]: HeadcountRange,
        [FilterName.REVENUE_BINS]: RevenueRange,
        [FilterName.COUNTRIES]: CountryOption,
        [FilterName.STATES]: StateOption,
        [FilterName.INDUSTRIES]: IndustryOption,
        [FilterName.CUSTOM_EXEGRAPHIC_SIGNALS]: CustomSignalOption,
    };

    const OptionComponent = optionComponents[filterName];

    if (!OptionComponent) {
        return null;
    }

    const props = {
        text,
        isSelected,
        ...(filterName === FilterName.INDUSTRIES && {isDisabled}),
    };
    return <OptionComponent {...props} />;
};

interface CustomSignalFilter {
    id: string;
    name: string;
    value: string;
}

interface SelectOptionProps {
    filterName: string;
    text: string;
    isSelected: boolean;
    isDisabled: boolean;
}

type OptionsValue = string | IntRange | CustomSignalFilter;

interface Options {
    value: OptionsValue;
    text: string;
    min: number;
    max?: number;
    isDisabled: boolean;
    signal?: CustomSignalFilter;
}

interface CheckListOptionProps {
    text: string;
    filterValue: OptionsValue;
    isSelected: boolean;
    isDisabled: boolean;
    addFilter(filterName: string, newValue: unknown): void;
    removeFilter(filterName: string, filterValue?: unknown): void;
    filterName: string;
}

export const CheckListOption = ({
    text,
    filterValue,
    isSelected = false,
    isDisabled,
    addFilter,
    removeFilter,
    filterName,
}: CheckListOptionProps) => {
    return (
        <MenuItem
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            className={isSelected ? 'selected-item' : ''}
            onClick={() => {
                if (isSelected) {
                    removeFilter(filterName, filterValue);
                } else {
                    addFilter(filterName, filterValue);
                }
            }}
            disabled={isDisabled}
        >
            <SelectOption
                filterName={filterName}
                text={text}
                isSelected={isSelected}
                isDisabled={isDisabled}
            />
        </MenuItem>
    );
};

interface SelectedWithProps {
    filters: AccountFilters;
    value: OptionsValue;
    filterName: string;
}

const isSelectedWithMinMax = ({
    filters,
    value,
    filterName,
}: SelectedWithProps): boolean => {
    if (!(filterName in filters)) return false;
    const filter: IntRange[] = filters[filterName] ?? [];
    if (typeof value !== 'string' && 'min' in value)
        return filter.some((element: IntRange): boolean => {
            return element.min === value.min && element.max === value.max;
        });
};

const isSelectedWithValue = ({
    filters,
    value,
    filterName,
}: SelectedWithProps): boolean => {
    if (!(filterName in filters)) return false;
    const filter: string[] = filters[filterName] ?? [];

    if (typeof value === 'string')
        return filter.some((element: string): boolean => element === value);
};

const isSelectedWithCustomSignal = ({
    filters,
    value,
    filterName,
}: SelectedWithProps): boolean => {
    if (!(filterName in filters)) return false;
    const filter = (filters[filterName] as CustomSignalFilter[]) ?? [];

    return filter.some(
        (element): boolean => element.id === (value as CustomSignalFilter).id
    );
};

const isSelected = ({filters, value, filterName}: SelectedWithProps) => {
    if (filterName === FilterName.CUSTOM_EXEGRAPHIC_SIGNALS)
        return isSelectedWithCustomSignal({filters, value, filterName});
    else if (
        filterName === FilterName.REVENUE_BINS ||
        filterName === FilterName.HEADCOUNT_BINS
    ) {
        return isSelectedWithMinMax({filters, value, filterName});
    }
    return isSelectedWithValue({filters, value, filterName});
};

interface CheckListFilterProps {
    addFilter(filterName: string, newValue: unknown): void;
    filterName: string;
    filters: AccountFilters;
    options: Partial<Options>[];
    removeFilter(filterName: string, filterValue?: unknown): void;
}

const CheckListFilter = ({
    filters,
    addFilter,
    removeFilter,
    options,
    filterName,
}: CheckListFilterProps) => {
    const isOptionsListLong =
        filterName === FilterName.INDUSTRIES ||
        filterName === FilterName.STATES ||
        filterName === FilterName.COUNTRIES ||
        filterName === FilterName.CUSTOM_EXEGRAPHIC_SIGNALS;

    const getOptionKey = (value: OptionsValue): string => {
        if (typeof value === 'string') {
            return value;
        }
        if ('id' in value) {
            return value.id;
        }
        if ('min' in value) {
            return `${value.min}`;
        }
    };

    return (
        <StyledMenuList
            autoFocusItem={true}
            className={isOptionsListLong ? 'long-menu' : ''}
            data-testid={`${filterName}-menu-list`}
        >
            {options.map(
                ({
                    text,
                    value = undefined,
                    isDisabled = false,
                }: Partial<Options>) => (
                    <CheckListOption
                        key={getOptionKey(value)}
                        text={text}
                        filterValue={value}
                        addFilter={addFilter}
                        removeFilter={removeFilter}
                        isSelected={isSelected({filters, value, filterName})}
                        filterName={filterName}
                        isDisabled={isDisabled}
                    />
                )
            )}
        </StyledMenuList>
    );
};

export default CheckListFilter;
