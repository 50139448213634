import {LinearProgress as MuiLinearProgress, Box} from 'components/mui';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const LinearProgress = styled(MuiLinearProgress)`
    &.MuiLinearProgress-colorPrimary {
        height: 3px;
        background-color: ${({theme}) => theme.palette.secondary.light};
    }
`;

export default function StyledLinearProgress({width, position, top}) {
    return (
        <Box
            width={width}
            position={position}
            top={top}
            data-testid="styled-linear-progress"
        >
            <LinearProgress />
        </Box>
    );
}

StyledLinearProgress.propTypes = {
    width: PropTypes.string,
    position: PropTypes.string,
    top: PropTypes.number,
};

StyledLinearProgress.defaultProps = {
    width: '75%',
    position: 'static',
    top: 0,
};
