import {Box} from 'components/mui';
import {FilterName} from 'core/Filters/index';
import AddIcon from 'components/ui/menu/AddIcon';
import CheckListFilter from 'components/ui/pages/aicp/lookalike-companies/filters/CheckListFilter';
import RemoveIcon from 'components/ui/menu/RemoveIcon';
import {CommonFilterProps} from 'components/ui/pages/aicp/lookalike-companies/FiltersMenu';

export const COUNTRY_OPTIONS = [
    {
        value: 'US',
        text: 'US',
    },
    {
        value: 'CA',
        text: 'Canada',
    },
];

interface CountryOptionProps {
    text: string;
    isSelected: boolean;
}

export const CountryOption = ({
    text,
    isSelected = false,
}: CountryOptionProps) => {
    return (
        <Box display="flex" width="100%" alignItems="center">
            <Box>{text}</Box>
            <Box flexGrow={1} />
            {isSelected ? <RemoveIcon /> : <AddIcon />}
        </Box>
    );
};

const CountryFilter = ({
    filters,
    addFilter,
    removeFilter,
}: CommonFilterProps) => {
    return (
        <CheckListFilter
            options={COUNTRY_OPTIONS}
            filters={filters}
            addFilter={addFilter}
            removeFilter={removeFilter}
            filterName={FilterName.COUNTRIES}
        />
    );
};

export default CountryFilter;
