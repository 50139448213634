import usePageDimensionsContext from 'components/hooks/usePageDimensionsContext';
import {Box, Grid, Tab, Tabs} from 'components/mui';
import type {NavigationTab} from 'components/types';
import {SubPageHeader} from 'components/ui/layouts/headers/SubPageHeader';
import Link from 'components/ui/links/Link';
import HeadTitle from 'components/ui/page/HeadTitle';
import styled from 'styled-components';

const shouldDisplayNavigationTabsBar = (
    navigationTabs: NavigationTab[]
): boolean => {
    return navigationTabs && navigationTabs.length > 0;
};

const ContentBox = styled(Box)`
    @media screen and (max-width: ${({theme}) =>
            theme.mobileToDesktopBreakpoint}px) {
        padding-top: 0;
    }
`;

const NavigationBar = styled(Box)`
    background-color: ${({theme}) => theme.palette.background.content};
    border-bottom: 1px solid ${({theme}) => theme.palette.action.disabled};
    padding: 0;

    ${({theme}) => theme.breakpoints.up('md')} {
        padding: 0 ${({theme}) => theme.spacing(1)};
    }
`;

const LinkTab = (
    props: {href: string} & React.ComponentPropsWithoutRef<typeof Tab>
) => {
    return (
        <Link href={props?.href}>
            <Tab {...props} component="span" />
        </Link>
    );
};

interface SubPageProps {
    title?: React.ReactNode;
    headerContent?: React.ReactNode;
    headerContainer?: React.ComponentType;
    children?: React.ReactNode;
    leftColumns?: React.ComponentPropsWithoutRef<typeof Grid>;
    rightColumns?: React.ComponentPropsWithoutRef<typeof Grid>;
    navigationTabs?: NavigationTab[];
    currentPageNavigationTab?: NavigationTab;
    navigationTabsBaseUrl?: string;
    useFixedHeader?: boolean;
}

const SubPage = ({
    title,
    headerContent = null,
    headerContainer,
    children,
    leftColumns,
    rightColumns,
    navigationTabs,
    currentPageNavigationTab,
    navigationTabsBaseUrl,
    useFixedHeader = false,
}: SubPageProps) => {
    const {navigationWidth} = usePageDimensionsContext();
    let headerStyles = {};
    if (useFixedHeader) {
        headerStyles = {
            position: 'fixed',
            height: '48px',
            width: `calc(100% - ${navigationWidth}px)`,
            zIndex: 100,
        };
    }

    if (title || headerContent || navigationTabs) {
        return (
            <>
                <Box sx={headerStyles}>
                    <HeadTitle>{title}</HeadTitle>
                    <SubPageHeader
                        title={title}
                        leftColumns={leftColumns}
                        rightColumns={rightColumns}
                        SubPageHeaderStyledContainer={headerContainer}
                    >
                        {headerContent}
                    </SubPageHeader>

                    {shouldDisplayNavigationTabsBar(navigationTabs) && (
                        <NavigationBar>
                            <Tabs
                                variant="scrollable"
                                scrollButtons="auto"
                                allowScrollButtonsMobile
                                value={currentPageNavigationTab.route}
                            >
                                {navigationTabsBaseUrl &&
                                    navigationTabs.map(({route, label}) => {
                                        return (
                                            <LinkTab
                                                key={route}
                                                href={`${navigationTabsBaseUrl}/${route}`}
                                                value={route}
                                                label={label}
                                            />
                                        );
                                    })}
                            </Tabs>
                        </NavigationBar>
                    )}
                </Box>

                <ContentBox>{children}</ContentBox>
            </>
        );
    }

    return <ContentBox>{children}</ContentBox>;
};

export {SubPage};
