import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline';
import styled from 'styled-components';

interface RemoveIconProps {
    partiallyselected?: string;
}

const partiallySelectedColor = '#FFA500';

const RemoveIcon = styled(RemoveCircleOutline)<RemoveIconProps>`
    color: ${({partiallyselected, theme}) =>
        partiallyselected && partiallyselected === 'true'
            ? partiallySelectedColor
            : theme.palette.primary.main};
    margin: 0px 5px;

    &.disabled {
        color: ${({theme}) => theme.palette.action.disabled};
    }
`;

export default RemoveIcon;
