import {Box} from 'components/mui';
import {FilterName} from 'core/Filters/index';
import RemoveIcon from 'components/ui/menu/RemoveIcon';
import AddIcon from 'components/ui/menu/AddIcon';
import CheckListFilter from 'components/ui/pages/aicp/lookalike-companies/filters/CheckListFilter';
import {CommonFilterProps} from 'components/ui/pages/aicp/lookalike-companies/FiltersMenu';
const REVENUE_FILTER_RANGES = [
    {
        value: {min: 0, max: 1000000},
        text: '$0 - $1M',
    },
    {
        value: {min: 1000000, max: 25000000},
        text: '$1M - $25M',
    },
    {
        value: {min: 25000000, max: 100000000},
        text: '$25M - $100M',
    },
    {
        value: {min: 100000000, max: 500000000},
        text: '$100M - $500M',
    },
    {
        value: {min: 500000000, max: 1000000000},
        text: '$500M - $1B',
    },
    {
        value: {min: 1000000000},
        text: '$1B+',
    },
];

interface RevenueRangeProps {
    text: string;
    isSelected: boolean;
}

export const RevenueRange = ({text, isSelected = false}: RevenueRangeProps) => {
    return (
        <Box display="flex" width="100%" alignItems="center">
            <Box>{text}</Box>
            <Box flexGrow={1} />
            {isSelected ? <RemoveIcon /> : <AddIcon />}
        </Box>
    );
};

const RevenueFilter = ({
    filters,
    addFilter,
    removeFilter,
}: CommonFilterProps) => {
    return (
        <CheckListFilter
            options={REVENUE_FILTER_RANGES}
            filters={filters}
            addFilter={addFilter}
            removeFilter={removeFilter}
            filterName={FilterName.REVENUE_BINS}
        />
    );
};

export default RevenueFilter;
