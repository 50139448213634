import {MenuList, MenuItem} from 'components/mui';
import styled from 'styled-components';

const DEFAULT_MENU_LIST_WIDTH = '320px';

export const StyledMenuItem = styled(MenuItem)`
    && {
        &.MuiMenuItem-root {
            padding: 0px;
            margin: 0px;
            border-bottom: 1px solid
                ${({theme}) => theme.palette.background.default};
            &&:last-child {
                border-bottom: none;
            }
        }
    }
`;

interface StyledMenuListProps {
    width?: string;
}

const StyledMenuList = styled(MenuList)<StyledMenuListProps>`
    width: ${({width}) => width || DEFAULT_MENU_LIST_WIDTH};
    background-color: white;
    color: ${({theme}) => theme.palette.secondary.main};
    font-weight: ${({theme}) => theme.typography.fontWeightSemibold};

    .MuiMenuItem-root {
        border-radius: 3px;
        padding: 6px;
        margin: 0px 10px;

        &:hover {
            background-color: ${({theme}) => theme.palette.background.default};
        }

        .menu-open {
            transition: transform 0.1s ease-in;
            color: ${({theme}) => theme.palette.primary.main};
            transform: translateX(-${({theme}) => theme.spacing(1)});
        }
    }

    .Mui-focusVisible {
        background-color: ${({theme}) => theme.palette.background.selected};
        color: ${({theme}) => theme.palette.primary.main};
    }

    .menu-title {
        border-bottom: 1px solid ${({theme}) => theme.palette.action.disabled};
        padding: 6px 16px 14px;
        margin-bottom: 6px;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }

    .menu-title span {
        color: ${({theme}) => theme.palette.secondary.dark};
        font-weight: ${({theme}) => theme.typography.fontWeightBold};
    }

    .selected-item {
        color: ${({theme}) => theme.palette.primary.main};
        background-color: ${({theme}) => theme.palette.background.selected};
        border: 1px solid ${({theme}) => theme.palette.primary.main};
    }

    &.long-menu {
        width: 375px;
        max-height: 400px;
        overflow: auto;
    }
`;

export default StyledMenuList;
