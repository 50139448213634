export enum FilterName {
    AICP_IDS = 'aicpIds',
    REV_SCORE_BINS = 'revScoreBins',
    TUNED_SCORE_BINS = 'tunedScoreBins',
    INDUSTRIES = 'industries',
    STATES = 'states',
    COUNTRIES = 'countries',
    REVENUE_BINS = 'revenueBins',
    EXEGRAPHIC_SIGNALS = 'exegraphicSignals',
    EXEGRAPHIC_LENS_NAMES = 'exegraphicLensNames',
    EXEGRAPHIC_SIGNALS_FILTER_OPERATOR = 'exegraphicSignalsFilterOperator',
    CUSTOM_SIGNAL_LINK_OPERATOR = 'customSignalLinkOperator',
    HEADCOUNT_BINS = 'headcountBins',
    COMPARISON_LIST_IDS = 'comparisonListIds',
    CUSTOM_EXEGRAPHIC_SIGNALS = 'customExegraphicSignals',
    INCLUDE_PREVIOUSLY_EXPORTED_TO_SALESFORCE = 'includePreviouslyExportedToSalesforce',
    INCLUDE_PREVIOUSLY_EXPORTED_TO_SALESFORCE_SANDBOX = 'includePreviouslyExportedToSalesforceSandbox',
    INCLUDE_PREVIOUSLY_EXPORTED_TO_CSV = 'includePreviouslyExportedToCsv',
    INCLUDE_PREVIOUSLY_EXPORTED_TO_HUBSPOT = 'includePreviouslyExportedToHubspot',
    INCLUDE_MAPPED_SALESFORCE_ACCOUNTS = 'includeMappedSalesforceAccounts',
    INCLUDE_SUPPRESSED = 'includeSuppressed',
    INCLUDE_DROPPED_URLS = 'includeDroppedUrls',
    SEEDS_ONLY = 'seedsOnly',
    DNB_REVENUE = 'dnbRevenue',
    DNB_HEADCOUNT = 'dnbHeadcount',
    DNB_INDUSTRIES = 'dnbIndustries',
}

export interface IntRange {
    min: number;
    max?: number;
}

export enum FilterOperatorOptions {
    AND = 'AND',
    OR = 'OR',
}
