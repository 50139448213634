import {Box} from 'components/mui';
import {FilterName} from 'core/Filters/index';
import RemoveIcon from 'components/ui/menu/RemoveIcon';
import AddIcon from 'components/ui/menu/AddIcon';
import CheckListFilter from 'components/ui/pages/aicp/lookalike-companies/filters/CheckListFilter';
import {CommonFilterProps} from 'components/ui/pages/aicp/lookalike-companies/FiltersMenu';

export const HEADCOUNT_FILTER_RANGES = [
    {
        value: {min: 1, max: 10},
        text: '1 - 10',
    },
    {
        value: {min: 11, max: 50},
        text: '11 - 50',
    },
    {
        value: {min: 51, max: 200},
        text: '51 - 200',
    },
    {
        value: {min: 201, max: 500},
        text: '201 - 500',
    },
    {
        value: {min: 501, max: 1000},
        text: '501 - 1000',
    },
    {
        value: {min: 1001, max: 5000},
        text: '1001 - 5000',
    },
    {
        value: {min: 5001, max: 10000},
        text: '5001 - 10000',
    },
    {
        value: {min: 10001},
        text: '10001+ ',
    },
];

export const DEFAULT_HEADCOUNT_BINS = [1, 2, 3, 4, 5, 6, 7];

interface HeadcountRangeProps {
    text: string;
    isSelected: boolean;
}

export const HeadcountRange = ({
    text,
    isSelected = false,
}: HeadcountRangeProps) => {
    return (
        <Box display="flex" width="100%" alignItems="center">
            <Box>{text}</Box>
            <Box flexGrow={1} />
            {isSelected ? <RemoveIcon /> : <AddIcon />}
        </Box>
    );
};

const HeadcountFilter = ({
    filters,
    addFilter,
    removeFilter,
}: CommonFilterProps) => {
    return (
        <CheckListFilter
            options={HEADCOUNT_FILTER_RANGES}
            filters={filters}
            addFilter={addFilter}
            removeFilter={removeFilter}
            filterName={FilterName.HEADCOUNT_BINS}
        />
    );
};

export default HeadcountFilter;
