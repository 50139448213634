import {Box} from 'components/mui';
import styled from 'styled-components';
import AddIcon from 'components/ui/menu/AddIcon';
import RemoveIcon from 'components/ui/menu/RemoveIcon';

const LabelBox = styled(Box)`
    overflow: hidden;
    text-overflow: ellipsis;
`;

interface IndustryOptionProps {
    text: string;
    isSelected: boolean;
    isDisabled: boolean;
}

const MenuIcon = ({
    isSelected,
    isDisabled,
}: {
    isSelected: boolean;
    isDisabled: boolean;
}) => {
    if (isSelected) {
        return <RemoveIcon />;
    }
    if (isDisabled) {
        return null;
    }
    return <AddIcon />;
};

const IndustryOption = ({
    text,
    isSelected = false,
    isDisabled,
}: IndustryOptionProps) => {
    return (
        <Box display="flex" width="100%" alignItems="center">
            <LabelBox>{text}</LabelBox>
            <Box flexGrow={1} />
            <MenuIcon isDisabled={isDisabled} isSelected={isSelected} />
        </Box>
    );
};

export default IndustryOption;
