const convertSnakeCaseToName = (inputString: string): string => {
    return inputString
        .split('_')
        .map((element) => {
            return element[0].toLocaleUpperCase() + element.slice(1);
        })
        .join(' ');
};

const removeEmptySpacesHyphensAndUnderscores = (input: string): string => {
    return input.replace(/[\s-_]+/g, '').toLowerCase();
};

export {convertSnakeCaseToName, removeEmptySpacesHyphensAndUnderscores};
