import {Box} from 'components/mui';
import Star from '@mui/icons-material/Star';
import styled from 'styled-components';
import {FilterName} from 'core/Filters/index';
import RemoveIcon from 'components/ui/menu/RemoveIcon';
import AddIcon from 'components/ui/menu/AddIcon';
import CheckListFilter from 'components/ui/pages/aicp/lookalike-companies/filters/CheckListFilter';
import {CommonFilterProps} from 'components/ui/pages/aicp/lookalike-companies/FiltersMenu';

const SCORE_FILTER_RANGES = [
    {
        value: 'FOUR',
        text: '4+',
    },
    {
        value: 'THREE',
        text: '3 to < 4',
    },
    {
        value: 'TWO',
        text: '2 to < 3',
    },
    {
        value: 'ONE',
        text: '1 to < 2',
    },
    {
        value: 'ZERO',
        text: '< 1',
    },
];

const FlexBox = styled(Box)`
    width: 100%;
    display: flex;
    align-items: center;
`;

const Filler = styled(Box)`
    flex-grow: 1;
`;

const StarIcon = styled(Star)`
    color: ${({theme}) => theme.palette.primary.main};
    margin: 0px 5px;
`;

interface RevScoreRangeProps {
    text: string;
    isSelected: boolean;
}
export const RevScoreRange = ({
    text,
    isSelected = false,
}: RevScoreRangeProps) => {
    return (
        <FlexBox>
            <StarIcon />
            <Box>{text}</Box>
            <Filler />
            {isSelected ? <RemoveIcon /> : <AddIcon />}
        </FlexBox>
    );
};

export const TunedScoreFilter = ({
    filters,
    addFilter,
    removeFilter,
}: CommonFilterProps) => {
    return (
        <CheckListFilter
            options={SCORE_FILTER_RANGES}
            filters={filters}
            addFilter={addFilter}
            removeFilter={removeFilter}
            filterName={FilterName.TUNED_SCORE_BINS}
        />
    );
};

const RevScoreFilter = ({
    filters,
    addFilter,
    removeFilter,
}: CommonFilterProps) => {
    return (
        <CheckListFilter
            options={SCORE_FILTER_RANGES}
            filters={filters}
            addFilter={addFilter}
            removeFilter={removeFilter}
            filterName={FilterName.REV_SCORE_BINS}
        />
    );
};

export default RevScoreFilter;
