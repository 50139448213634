import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import styled from 'styled-components';

interface AddIconProps {
    partiallyselected?: string;
}

const AddIcon = styled(AddCircleOutlineIcon)<AddIconProps>`
    color: ${({partiallyselected, theme}) =>
        partiallyselected && partiallyselected === 'true'
            ? theme.palette.action.partiallySelected
            : theme.palette.secondary.main};

    margin: 0px 5px;

    &.disabled {
        color: ${({theme}) => theme.palette.action.disabled};
    }
`;

export default AddIcon;
