import {parse, unparse, ParseResult} from 'papaparse';
import {removeEmptySpacesHyphensAndUnderscores} from 'utils/strings';
import XLSX from 'xlsx';

const SUPPORTED_FILE_EXTENSIONS = {
    csv: 'CSV',
    xls: 'Excel',
    xlsx: 'Excel',
    xlsm: 'Excel',
};

const HEADER_EXTRACTION_ERROR = 'Header Extraction Error';
const CONTENT_EXTRACTION_ERROR = 'Content Extraction Error';
const HEADER_REPLACEMENT_ERROR = 'Header Replacement Error';
const PAPA_PARSE_SKIP_LINES_WITH_NO_CONTENT_STRING = 'greedy';
const PAPA_PARSE_VALUE_TO_PARSE_ALL_ROWS = 0;

export interface FileWithPath extends File {
    path: string;
}

const cleanRowValues = (row: string[]): string[] => {
    const trimmedValues = row.map((value) => value.trim());

    return trimmedValues;
};

const extractHeaders = async (file: FileWithPath): Promise<string[]> => {
    try {
        return await _extractHeaderFromFile(file);
    } catch (error) {
        throw Error(
            HEADER_EXTRACTION_ERROR,
            _formatExtractHeaderErrorMessage(error, file)
        );
    }
};

const extractDataRows = async (
    file: FileWithPath,
    startingRowIndex: number
): Promise<string[][]> => {
    try {
        return await _extractDataRowsFromFile(file, startingRowIndex);
    } catch (error) {
        throw Error(CONTENT_EXTRACTION_ERROR, error);
    }
};

const fileExtension = (file: FileWithPath): string => {
    return file.name.split('.').slice(-1)[0];
};

const replaceHeaders = async (
    file: FileWithPath,
    newHeader: string,
    oldHeader: string
): Promise<FileWithPath> => {
    try {
        return await _replaceHeader(file, newHeader, oldHeader);
    } catch (error) {
        throw Error(HEADER_REPLACEMENT_ERROR, error);
    }
};

const _getSupportedFileExtensionOrThrowError = (file: FileWithPath): string => {
    const fileExtension = file.name.split('.').slice(-1)[0];
    const supportedFileExtension: string =
        SUPPORTED_FILE_EXTENSIONS[fileExtension];

    if (!supportedFileExtension) {
        throw Error('File Extraction Error', {
            cause: 'Unsupported file format',
        });
    }

    return supportedFileExtension;
};

const _extractHeaderFromFile = async (
    file: FileWithPath | string
): Promise<string[]> => {
    const fileExtension = _getSupportedFileExtensionOrThrowError(
        file as FileWithPath
    );

    if (fileExtension === 'Excel') {
        file = await _promiseToConvertExcelIntoCsv(file as FileWithPath);
    }

    const contents = await _promiseToGetCsvContents(file, 2);
    const header: string[] = contents[0];

    _validateHeader(header);
    const v = _headerWithNoTrailingEmptyColumns(header);

    return v;
};

const _promiseToGetCsvContents = (
    csvFile: string | FileWithPath,
    numberOfRowsToParse: number = PAPA_PARSE_VALUE_TO_PARSE_ALL_ROWS
): Promise<Array<string[]>> => {
    return new Promise(function (resolve, reject) {
        parse(csvFile, {
            delimiter: ',',
            preview: numberOfRowsToParse,
            skipEmptyLines: PAPA_PARSE_SKIP_LINES_WITH_NO_CONTENT_STRING,
            complete: function (results) {
                if (results.errors && results.errors.length > 0) {
                    reject(results.errors[0]);
                } else {
                    resolve(results.data);
                }
            },
        });
    });
};

const _replaceHeader = async (
    csvFile: FileWithPath,
    newHeader: string,
    oldHeader: string
): Promise<FileWithPath> => {
    return new Promise(function (resolve, reject) {
        parse(csvFile, {
            delimiter: ',',
            preview: 0,
            skipEmptyLines: PAPA_PARSE_SKIP_LINES_WITH_NO_CONTENT_STRING,
            complete: function (results: ParseResult<string[]>) {
                if (results.errors && results.errors.length > 0) {
                    reject(results.errors[0]);
                } else {
                    const newHeaders: string[] = results.data[0].map(
                        (header) => {
                            const cleanedHeader =
                                removeEmptySpacesHyphensAndUnderscores(header);
                            if (cleanedHeader === oldHeader) {
                                return newHeader;
                            }
                            return header;
                        }
                    );

                    results.data[0] = newHeaders;

                    const modifiedData = unparse(results.data);
                    const blob = new Blob([modifiedData], {
                        type: 'text/csv;charset=utf-8',
                    });

                    const newFile = new File([blob], csvFile.name, {
                        type: 'text/csv;charset=utf-8',
                    });

                    const newFileWithPath: FileWithPath =
                        newFile as FileWithPath;
                    newFileWithPath.path = csvFile.path;

                    resolve(newFileWithPath);
                }
            },
        });
    });
};

const _promiseToConvertExcelIntoCsv = (
    excelFile: FileWithPath
): Promise<string> => {
    return new Promise(function (resolve, reject) {
        const reader = new FileReader();

        reader.readAsBinaryString(excelFile);

        reader.onloadend = function (e) {
            const workbook = XLSX.read(e.target.result, {type: 'binary'});
            const firstSheetName = workbook.SheetNames[0];
            const firstSheet = workbook.Sheets[firstSheetName];

            resolve(XLSX.utils.sheet_to_csv(firstSheet, {strip: true}));
        };

        reader.onerror = function (error) {
            reject(error);
        };
    });
};

const _formatExtractHeaderErrorMessage = (
    error: Error,
    file: FileWithPath
): string | unknown => {
    const supportedFileExtension = _getSupportedFileExtensionOrThrowError(file);

    if (error.message && supportedFileExtension === 'CSV') {
        return 'Cannot load file. Please check CSV for common issues like missing a header.';
    } else if (error.message && supportedFileExtension === 'Excel') {
        return 'Cannot load file. Please try formatting to CSV and try again.';
    }

    return error.cause || error;
};

const _validateHeader = (headers: string[]): void => {
    if (!headers) {
        throw Error(HEADER_EXTRACTION_ERROR, {cause: 'Header is missing'});
    }

    _headerWithNoTrailingEmptyColumns(headers).forEach((entry, index) => {
        if (!entry) {
            throw Error(HEADER_EXTRACTION_ERROR, {
                cause: `Header in column ${index + 1} is empty`,
            });
        }
    });
};

const _headerWithNoTrailingEmptyColumns = (header: string[]): string[] => {
    while (header[header.length - 1] === '') {
        header.pop();
    }

    if (!header) {
        throw Error(HEADER_EXTRACTION_ERROR, {cause: 'Header is missing'});
    }

    return header;
};

const _extractDataRowsFromFile = async (
    file: FileWithPath,
    startingRowIndex: number
): Promise<string[][]> => {
    const dataRows = await _promiseToGetCsvContents(
        file,
        PAPA_PARSE_VALUE_TO_PARSE_ALL_ROWS
    );

    dataRows.splice(0, startingRowIndex);

    return dataRows;
};

export {
    cleanRowValues,
    extractHeaders,
    extractDataRows,
    fileExtension,
    replaceHeaders,
    SUPPORTED_FILE_EXTENSIONS,
};
