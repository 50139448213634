import {Box} from 'components/mui';
import AddIcon from 'components/ui/menu/AddIcon';
import RemoveIcon from 'components/ui/menu/RemoveIcon';

interface CustomSignalOptionProps {
    text: string;
    isSelected: boolean;
}

const CustomSignalOption = ({
    text,
    isSelected = false,
}: CustomSignalOptionProps) => {
    return (
        <Box display="flex" width="100%" alignItems="center">
            <Box>{text}</Box>
            <Box flexGrow={1} />
            {isSelected ? <RemoveIcon /> : <AddIcon />}
        </Box>
    );
};

export default CustomSignalOption;
