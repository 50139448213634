import {Grid, Typography} from 'components/mui';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SubPageHeaderStyledContainer = styled(Grid)`
    z-index: 900;
    width: 100%;

    align-items: center;
    white-space: nowrap;
    padding: ${({theme}) => theme.spacing(1, 3)};

    border-bottom: 1px solid ${({theme}) => theme.palette.action.disabled};
    background-color: ${({theme}) => theme.palette.background.default};

    .MuiGrid-item {
        display: flex;
        justify-content: center;
    }

    .subpage-title {
        justify-content: flex-start;
        margin-bottom: 0;
    }

    ${({theme}) => theme.breakpoints.up('md')} {
        .subpage-actions {
            justify-content: flex-end;
        }
    }

    ${({theme}) => theme.breakpoints.up('lg')} {
        flex-wrap: nowrap;
    }
`;

const defaultLeftColumns = {
    xs: 12,
    md: true,
};

const defaultRightColumns = {
    xs: 12,
    md: 'auto',
};

const hasTitle = (title) => title && title.length > 0;

const SubPageHeader = ({
    title,
    children,
    borderLeft,
    leftColumns,
    rightColumns,
    SubPageHeaderStyledContainer,
}) => {
    return (
        <SubPageHeaderStyledContainer container>
            <Grid item className="subpage-title" {...leftColumns}>
                {hasTitle(title) && (
                    <Typography
                        variant="h6"
                        component="h2"
                        noWrap
                        marginRight={{
                            xs: 0,
                            md: children && 3,
                        }}
                    >
                        {title}
                    </Typography>
                )}
            </Grid>

            <Grid item className="subpage-actions" {...rightColumns}>
                {children}
            </Grid>
        </SubPageHeaderStyledContainer>
    );
};
SubPageHeader.propTypes = {
    title: PropTypes.any,
    children: PropTypes.any,
    borderLeft: PropTypes.string,
    SubPageHeaderStyledContainer: PropTypes.elementType,
    leftColumns: PropTypes.object,
    rightColumns: PropTypes.object,
};

SubPageHeader.defaultProps = {
    SubPageHeaderStyledContainer,
    leftColumns: defaultLeftColumns,
    rightColumns: defaultRightColumns,
};

export {SubPageHeader, SubPageHeaderStyledContainer};
